<template>
  <div class="prev-canvas-modal">
    <div class="title">Template File</div>
    <div class="file-name">{{ item.name }}</div>
    <div v-if="!imgSrc" class="img-wrap">
      <a-spin :spinning="loading">
        <div class="img-canvas">
          <canvas-prev-img :project-store="projectStore" :current-id="currentId" />
        </div>
        <div v-show="!loading" class="btns">
          <span
            v-for="canvas in canvasList"
            :key="canvas.id"
            :class="['btn', canvas.id == currentId ? 'active' : '']"
            :title="formatTitle(canvas.title)"
            @click="changeCanvas(canvas.id)"
          >
            {{ formatTitle(canvas.title) }}
          </span>
        </div>
      </a-spin>
    </div>
    <div v-else class="img-wrap">
      <div class="img-canvas">
        <img :src="imgSrc" alt="" />
      </div>
      <div class="btns">
        <span class="btn active">{{ formatTitle(imgTitle) }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { canvasListType } from '@/types/canvas';
type Props = {
  visible: boolean;
  item: canvasListType;
};
const props = defineProps<Props>();
const close = () => {
  canvasList.value = [];
  currentId.value = '';
  editorStore.value.destroyEditor();
};
const changeCanvas = async (id: string) => {
  editorStore.value.editorChangeCanvas(id);
};
const editorStore = ref(null) as any;
const canvasList = ref([] as any);
const currentId = ref('');

const initEditor = async () => {
  const { useEditorStore } = await import('@/stores/editor');
  editorStore.value = useEditorStore();
  editorStore.value.initEditor();
};
const imgSrc = ref('');
const imgTitle = ref('');
watch(
  () => editorStore.value?.currentId,
  () => {
    canvasList.value = editorStore.value?.canvasList;
    currentId.value = editorStore.value?.currentId;
  },
  { deep: true },
);
const projectStore = ref(null) as any;
const loading = computed(() => projectStore.value?.projectLoading);
const canvasListLength = ref(0);
const initFile = async () => {
  const { item } = props;

  // 有预览图且只有一张画布的时候用预览图，不解析文件
  if (item.extra && item.extra.cover) {
    canvasListLength.value = item.extra.canvasIds.length;
    if (canvasListLength.value === 1 && item.extra.cover) {
      imgSrc.value = item.extra.cover;

      imgTitle.value = item.extra.canvasIds[0].title || 'Canvas1';
      return;
    }
  }
  const { useProjectStore } = await import('@/stores/project');
  projectStore.value = useProjectStore();
  projectStore.value.projectOpenByUrl({
    name: props.item.name,
    url: props.item.url,
  });

  initEditor();
};
const formatTitle = (str: string) => {
  const res = str.replace(/({panel})/g, 'Canvas');
  return res;
};
onMounted(() => {
  nextTick(initFile);
});
watch(
  () => props.visible,
  () => {
    if (props.visible === true) {
      initFile();
    } else {
      close();
    }
  },
);
</script>
<style lang="less" scoped>
.prev-canvas-modal {
  .title {
    color: #212121;
    font-weight: theme('fontWeight.semibold');
    font-size: 30px;
    font-weight: theme('fontWeight.semibold');
    line-height: 38px; /* 126.667% */
  }
  .file-name {
    color: #555;

    font-size: 18px;
    font-weight: theme('fontWeight.semibold');
    line-height: 26px;
    text-align: center;
    margin: 8px 0;
  }
  .img-wrap {
    height: 390px;
    border-radius: 8px;
    background: #f8f8f8;
    overflow: hidden;
    border: 1.2px dashed rgba(0, 0, 0, 0.16);

    .img-canvas {
      height: 356px;
      padding: 8px 20px;

      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    .btns {
      height: 32px;

      background: #fff;
      box-shadow:
        0px 1px 20px 0px rgba(0, 0, 0, 0.1),
        0px 1px 2px 0px rgba(0, 0, 0, 0.06);
      display: flex;
      overflow-y: hidden;
      overflow-x: scroll;

      /* 滚动条整体 */
      &::-webkit-scrollbar {
        width: 0px;
        display: none;
      }
      /* 两个滚动条交接处 -- x轴和y轴 */
      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 0;
        width: 0px;
        &:vertical {
          background-color: transparent;
        }
      }
      /* 滚动条轨道 */
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      .btn {
        padding: 0 16px;
        display: block;
        height: 32px;
        color: #555;

        cursor: pointer;
        line-height: 32px;
        text-align: center;
        white-space: nowrap;
        &.active {
          background: #f4f4f4;
          color: #212121;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .prev-canvas-modal {
    .title {
      font-size: 18px;
      line-height: 18px;
    }
    .file-name {
      font-size: 16px;
    }
    .img-wrap {
      height: 325px;
      .img-canvas {
        height: 290px;
      }
      .btns {
        .btn {
          padding: 0 8px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
